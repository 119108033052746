/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

const StyledContentDefault = styled.div`
  font-size: ${props => props.theme.font.size.m};
  color: ${props => props.theme.color.text.main};
  line-height: ${props => props.theme.font.size.ml};

  & h1, & h2, & h3 {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.xm};
    font-weight: ${props => props.theme.font.weight.l};
    color: ${props => props.theme.color.text.main};
    line-height: ${props => props.theme.font.size.xl};
  }

  & blockquote {
    text-align: center;
    margin: 25px 0 45px;
  }

  & a {
    font-weight: ${props => props.theme.font.weight.l};
    color: ${props => props.theme.color.text.contrast};
    
    &:hover {
      text-decoration: underline;
    }
  }
`

const ContentDefault = ({ content, className }) => (
  <StyledContentDefault className={className}>
    <ParseContent content={content} />
  </StyledContentDefault>
)

export default ContentDefault
